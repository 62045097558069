import React, {useState, useEffect} from 'react';
import './header.scss'
import './button.scss'

import { Link as ReactScrollLink} from 'react-scroll'

function Header() {
    const [menuIsActive, setMenuIsActive] = useState(false)
    const [onTop, setOnTop] = useState(true)

    useEffect( () => {
      window.addEventListener('scroll', () => {
        if(!window.scrollY){
          setOnTop(true)
        }
        else{
          setOnTop(false)
        }
      })
    },[])
    
    return ( 
        <header className={!onTop ? 'not-on-top' : undefined}>
            <div className="menu-btn" onClick={() => setMenuIsActive(!menuIsActive)}>
              <div className="line" style={{backgroundColor: onTop ? 'white' : 'black'}}></div>
              <div className="line" style={{backgroundColor: onTop ? 'white' : 'black'}}></div>
              <div className="line" style={{backgroundColor: onTop ? 'white' : 'black'}}></div>             
            </div>

            <a className="logo" href="/">
              <img src='https://www.dropbox.com/s/gwtcrsahetmhqzk/joy_branco.png?raw=1' alt="logo..." style={{display: onTop ? 'block' : 'none'}}/>  
              <img src='https://www.dropbox.com/s/3agjnyp7gf7916n/joy_preto.png?raw=1' alt="logo..." style={{display: !onTop ? 'block' : 'none'}}/>
            </a>

            <a className="agendamento" href='https://api.whatsapp.com/send/?phone=%2B5565992840626&text=Olá&app_absent=0' target= "_blank"> 
                <button className={`btn rounded ${!onTop && 'not-on-top'}`}>
                  <span className="text-green" style={{color: onTop ? 'white' : 'black'}}>
                    AGENDE AQUI
                  </span>
                </button>
            </a>


            <div className="menu_container" style={{ transform: menuIsActive ? 'translateX(100vw)' : 'translateX(0px)' }}>
              <div className="menu_ui">
                <img 
                  src="https://www.dropbox.com/s/e61kb1msm3jdlk2/close.png?raw=1" 
                  alt="botao fechar" 
                  className='close_icon'
                  onClick={() => setMenuIsActive(!menuIsActive)}
                />

                <ReactScrollLink to='inicio' smooth duration={500} offset={-100} onClick={ () => setMenuIsActive(false)}>
                  <p>
                    Início
                  </p>
                </ReactScrollLink>
                <ReactScrollLink to='especialidades' smooth duration={500} offset={0} onClick={ () => setMenuIsActive(false)}>
                  <p>
                    Especialidades
                  </p>
                </ReactScrollLink>
                <ReactScrollLink to='sobre' smooth duration={500} offset={-100} onClick={ () => setMenuIsActive(false)}>
                  <p>
                    Onde tudo começou
                  </p>
                </ReactScrollLink>
                <ReactScrollLink to='ondeestamos' smooth duration={500} offset={0} onClick={ () => setMenuIsActive(false)}>
                  <p>
                    Nosso Espaço
                  </p>
                </ReactScrollLink>
                
                <div className="divisor"></div>
                <a href='https://api.whatsapp.com/send/?phone=%2B5565992840626&text=Olá&app_absent=0'>
                  <p>
                    Agende aqui
                  </p>
                </a>
                {/* <p>
                  <a href="https://www.facebook.com/profile.php?id=100084853303955 ">
                    Facebook
                  </a>
                </p> */}
                <p>
                  <a href="https://www.instagram.com/joy.odonto/">
                    Instagram
                  </a>
                </p>
              </div>
            </div>
            
        </header>
     );
}

export default Header;