import React, {useState} from 'react';
import './inicio.scss'
import './button.scss'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Loader from '../../../components/loader/Loader';

const settings = {
  dots: false,
  infinite: true,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2500,
  autoplaySpeed: 1000,
  draggable: false
}

function Inicio() {
    const [isLoading, setIsLoading] = useState(true)

    return ( 
        <section className="inicio" id="inicio">
            <a className="agendamento-inicio" href='https://api.whatsapp.com/send/?phone=%2B5565992840626&text=Olá&app_absent=0' target="_blank"> 
              <button className="btn-inicio rounded"><span className="text-green">AGENDE AQUI</span></button>
            </a>
            <div className="slogan-text">
                Seu sorriso reflete nossa alegria
            </div>
            <div className='bg-filter'/>

            <div className={`bg`}>
              {
                window.innerWidth >= 961 ? 
                <Slider {...settings}>
                  <div style={{ width: 100 }}>
                    <img src='https://www.dropbox.com/s/76s9ezbrwhf6a0y/001.png?raw=1' alt="fundo1..." className='fundo' onLoad={() => setIsLoading(false)}/>
                  </div>
                  <div style={{ width: 100 }}>
                    <img src='https://www.dropbox.com/s/n25irorev2qppf7/002.png?raw=1' alt="fundo2..." className='fundo'/>
                  </div>
                  <div style={{ width: 100 }}>
                    <img src='https://www.dropbox.com/s/094k2ybjuwggabu/003.png?raw=1' alt="fundo3..." className='fundo'/>
                  </div>
                </Slider>
                :
                <Slider {...settings}>
                  <div style={{ width: 100 }}>
                    <img src='https://www.dropbox.com/s/403if3j24nyxav3/as.png?raw=1' alt="fundo1..." className='fundo' onLoad={() => setIsLoading(false)}/>
                  </div>
                  <div style={{ width: 100 }}>
                    <img src='https://www.dropbox.com/s/4naifshv8vcncoz/IMG_5233-1.png?raw=1' alt="fundo2..." className='fundo' onLoad={() => setIsLoading(false)}/>
                  </div>
                  <div style={{ width: 100 }}>
                    <img src='https://www.dropbox.com/s/gxfgj54avzihziz/Sem-T%C3%ADtulo-1.png?raw=1' alt="fundo1..." className='fundo' onLoad={() => setIsLoading(false)}/>
                  </div>
                </Slider>
              }
            </div>
            
            <Loader isLoading={isLoading}/>
        </section>
     );
}

export default Inicio;