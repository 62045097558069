import React, { useState, useEffect } from 'react';
import './styles.scss'

function Loader({ isLoading }) {
  const [classes, setClasses] = useState('not-load')

  useEffect( () => {
    if(!isLoading){
      setTimeout(() => setClasses('is-load'), 500)
    }
  },[isLoading])
  return ( 
    <div className={`loader-container ${classes}`} style={{opacity: isLoading ? '1' : '0'}}>
      <div className="loader"></div>
    </div>
   );
}

export default Loader;