import React, { useState } from 'react';
import './ondeestamos.scss'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Map from './map/Map';

import { LazyLoadImage } from 'react-lazy-load-image-component'

const photosArray = [
  'https://www.dropbox.com/s/j6okehl0hvynu8z/Foto%2010-06-2022%2015%2006%2055.jpg?raw=1',
  'https://www.dropbox.com/s/b75ggzfbk54rkfh/Foto%2010-06-2022%2015%2006%2057%20%281%29.jpg?raw=1',
  'https://www.dropbox.com/s/4ywk4jtqx16aqoy/Foto%2010-06-2022%2015%2006%2057%20%282%29.jpg?raw=1',
  'https://www.dropbox.com/s/ipv8dpzy0h8p227/Foto%2010-06-2022%2015%2006%2057.jpg?raw=1',
  'https://www.dropbox.com/s/ko4q46zjfu6u0w0/Foto%2010-06-2022%2015%2007%2002%20%281%29.jpg?raw=1',
  'https://www.dropbox.com/s/lu5idff7asv62w2/Foto%2010-06-2022%2015%2006%2058%20%281%29.jpg?raw=1',
  'https://www.dropbox.com/s/pg0uxrng3a5dunf/Foto%2010-06-2022%2015%2006%2058.jpg?raw=1',
  'https://www.dropbox.com/s/mjis0e62dxzq3h0/Foto%2010-06-2022%2015%2006%2059.jpg?raw=1',
  'https://www.dropbox.com/s/60txa40o7k08f4n/Foto%2010-06-2022%2015%2007%2000%20%281%29.jpg?raw=1',
  'https://www.dropbox.com/s/tpeiqof8qil2so8/Foto%2010-06-2022%2015%2007%2000.jpg?raw=1',
  'https://www.dropbox.com/s/anjd3ztxqiuu93s/Foto%2010-06-2022%2015%2007%2001%20%281%29.jpg?raw=1',
  'https://www.dropbox.com/s/t85xja1ligquk85/Foto%2010-06-2022%2015%2007%2001%20%282%29.jpg?raw=1',
  'https://www.dropbox.com/s/n72iol97ixfkmc9/Foto%2010-06-2022%2015%2007%2001.jpg?raw=1',
  'https://www.dropbox.com/s/qiwy7sgf5llukps/Foto%2010-06-2022%2015%2007%2003%20%281%29.jpg?raw=1',
  'https://www.dropbox.com/s/8vvgookqu0zf8y7/Foto%2010-06-2022%2015%2007%2003%20%282%29.jpg?raw=1',
  'https://www.dropbox.com/s/eas3hg4na78cdr4/Foto%2010-06-2022%2015%2007%2003.jpg?raw=1',
  'https://www.dropbox.com/s/tw94ux46pln494d/Foto%2010-06-2022%2015%2007%2004%20%281%29.jpg?raw=1',
  'https://www.dropbox.com/s/cyihue9ceehaxft/Foto%2010-06-2022%2015%2007%2004.jpg?raw=1',
  'https://www.dropbox.com/s/e96m3zhgcte758p/Foto%2010-06-2022%2015%2007%2005%20%281%29.jpg?raw=1',
  'https://www.dropbox.com/s/k0yuznrj75qyt0l/Foto%2010-06-2022%2015%2007%2005.jpg?raw=1', 
]

function OndeEstamos() {
  const [isMuted, setIsMuted] = useState(true)

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 530,
        settings: {
          centerPadding: '-280px'
        }
      }
    ]
  }
  return ( 
    <section className='onde-estamos' id='ondeestamos'>
      <div className='row_main'>
        <div className="joy">
          J <br /> O <br /> Y
        </div>
        <div className="video">
          <video 
            autoPlay 
            muted={isMuted}
            loop 
          >
            <source src="https://www.dropbox.com/s/bm6eddkcypmuitz/JOY%20ESPA%C3%87O.mp4?raw=1" type="video/mp4"></source>
            <source src="https://www.dropbox.com/s/cag9405utp9fxsz/JOY-ESPA%C3%87O.webm?raw=1" type="video/webm"></source>
          </video>
          <div className="mute-btn" onClick={() => setIsMuted(!isMuted)}>
            <img src="https://www.dropbox.com/s/e5tk0fvob9jh2w3/muted.png?raw=1" alt="Mutar"  style={{display: isMuted ? 'block' : 'none'}}/>
            <img src="https://www.dropbox.com/s/4ubro2ebma46snv/not-muted.png?raw=1" alt="Mutar" style={{display: !isMuted ? 'block' : 'none'}}/>
          </div>

        </div>
        <div className="map">
          <Map/>
        </div>
        <div className='localizacao'>
          <LazyLoadImage 
            src="https://www.dropbox.com/s/6w9fhfwx8si3xbh/marcador.png?raw=1" 
            alt="" 
            className='marcador'
          />
          <div className="text">ONDE ESTAMOS</div>
        </div>
      </div>
      <div className="row_slider">
        <Slider {...settings}>
            {
             photosArray.map( photo => {
                return (
                  <div key={photo}>
                    <img
                      src={photo}
                      alt="Fotos espaço" 
                      className='img_slider'
                      effect="blur"
                    />
                  </div>
                )
              })
            }  
        </Slider>
      </div>
    </section>
   );
}

export default OndeEstamos;