import React from 'react';
import './footer.scss';
import styled from 'styled-components';
import { AiFillInstagram } from 'react-icons/ai';


import { Link as ReactScrollLink } from 'react-scroll';

function Footer() {
  const year = new Date().getFullYear();
  const instagramURL = 'https://www.instagram.com/joy.odonto/';

  return (
    <footer>
      <a href={instagramURL} target="_blank" rel="noopener noreferrer">
        <ion-icon name="logo-instagram"></ion-icon>
      </a>
      <div className="EntrarInstagram">
        <p>
          <a href='https://www.instagram.com/joy.odonto/'> Entre no nosso Instagram </a>
        </p>
        <a href='https://www.instagram.com/joy.odonto/'>

        </a>
      </div>
      <div className='company'>
        <a href='/' className="icon_container">
          <img src="https://www.dropbox.com/s/3agjnyp7gf7916n/joy_preto.png?raw=1" alt="logo preta" className='icon' />
        </a>
        <div className="content">
          {year} {""} ©
          <br />
          <strong>CLÍNICA JOY</strong>
          <br />
          <span>TODOS OS DIREITOS RESERVADOS</span>
        </div>
      </div>
      <div className="indexing">
        <div className="column">
          <strong>INÍCIO</strong>
          <ReactScrollLink to='inicio' smooth duration={500} offset={-100}>
            <p>
              Início
            </p>
          </ReactScrollLink>
          <ReactScrollLink to='sobre' smooth duration={500} offset={-100}>
            <p>
              Onde tudo começou
            </p>
          </ReactScrollLink>
          <ReactScrollLink to='ondeestamos' smooth duration={500} offset={0}>
            <p>
              Nosso Espaço
            </p>
          </ReactScrollLink>
          <ReactScrollLink to='especialidades' smooth duration={500} offset={0}>
            <p>
              Especialidades
            </p>
          </ReactScrollLink>
          <a href='https://api.whatsapp.com/send/?phone=%2B5565992840626&text=Olá&app_absent=0'>
            <p>
              Agende aqui
            </p>
          </a>
        </div>
        <div className="column">
          <strong>ESPECIALIDADES</strong>
          <p onClick={() => window.dispatchEvent(new CustomEvent('popstate'))}>
            <a href="/#bucomaxilofacial-e-implantes-dentarios">
              Bucomaxilo e implantes dentários
            </a>
          </p>
          <p onClick={() => window.dispatchEvent(new CustomEvent('popstate'))}>
            <a href="/#odontopediatra">
              Odontopediatria
            </a>
          </p>
          <p onClick={() => window.dispatchEvent(new CustomEvent('popstate'))}>
            <a href="/#endodontia">
              Endodontia
            </a>
          </p>
          <p onClick={() => window.dispatchEvent(new CustomEvent('popstate'))}>
            <a href="/#estetica-dental">
              Estética dental
            </a>
          </p>
          <p onClick={() => window.dispatchEvent(new CustomEvent('popstate'))}>
            <a href="/#invisalign">
              Invisalign
            </a>
          </p>
          <p onClick={() => window.dispatchEvent(new CustomEvent('popstate'))}>
            <a href="/#harmonizacao-facial">
              Harmonizacao Facial
            </a>
          </p>
        </div>
      </div>
    </footer>
  );


}

export default Footer;

