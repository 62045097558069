import React from 'react';
import './sobre.scss'



function Sobre() {
  return ( 
    <section className="sobre" id='sobre'>
      
      <img src='./beccaejorge.jpg' alt="Donos" />
      <div className="text_container">
        <div className="title">
          Onde tudo começou
        </div>
        <div className="content">
          <p>
            A Joy nasceu com a missão de ser muito mais do que odontologia, muito além de um sorriso bonito, nasceu com intuito de devolver alegria genuína, autoestima e qualidade de vida.
            Somos uma clínica especializada com profissionais de diversas áreas para respeitarmos as particularidades de cada paciente.
          </p>
          <p>
            Reunimos as áreas de bucomaxilofacial, implantes dentários, odontopediatria, dentística, endodontia, harmonia facial e ortodontia para entregarmos o melhor a cada um de vocês.
          </p>

          <p>
            A materialização desse espaço foi cuidadosamente pensado para que vocês tenham uma experiência diferenciada com dentistas, por isso, ela não poderia ter outro nome.
          </p>

          <p>
            Joy, que significa alegria, mas que diz muito mais do que isso:
          </p>

          <p>
            Joy é contemplar!
            <br />
            Joy é felicidade!
            <br />
            Joy é prazer!
          </p>
          <p>
            Que a  Joy possa entregar tantos sorrisos para vocês, como ela já entregou para nós.
          </p>
          <p>
            Com amor, Rebecca Custódio e Jorge Borek.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Sobre;