import React, { useEffect, useState } from 'react';
import './card.scss'

import { scroller } from 'react-scroll'

import { styled, keyframes } from '@stitches/react';
import { violet, blackA, mauve } from '@radix-ui/colors';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const StyledAccordion = styled(AccordionPrimitive.Root, {
  borderRadius: 6,
  backgroundColor: 'transparent',
  boxShadow: `0 2px 10px ${blackA.blackA4}`,
});

const StyledItem = styled(AccordionPrimitive.Item, {
  overflow: 'hidden',
  marginTop: 10,
  borderRadius: 10,

  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
    boxShadow: `0 0 0 2px ${mauve.mauve12}`,
  },
});

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: 'unset',
  display: 'flex',
  fontSize: '1em',
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  backgroundColor: 'transparent',
  padding: '10px 30px',
  height: 45,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '.9em',
  fontWeight: '800',
  lineHeight: 1,
  color: 'black',
  boxShadow: `0 1px 0 ${mauve.mauve6}`,
  '&[data-state="closed"]': { backgroundColor: 'white' },
  '&[data-state="open"]': { backgroundColor: 'white' },
  '&:hover': { backgroundColor: mauve.mauve2, cursor: 'pointer' },
});

const StyledContent = styled(AccordionPrimitive.Content, {
  overflow: 'hidden',
  fontSize: '.7em',
  color: 'black',
  backgroundColor: mauve.mauve2,

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
  },
});

const StyledContentText = styled('div', {
  padding: '15px 20px',
  color: '#00000'
});

const StyledChevron = styled(ChevronDownIcon, {
  color: violet.violet10,
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '[data-state=open] &': { transform: 'rotate(180deg)' },
});

// Exports
export const Accordion = StyledAccordion;
export const AccordionItem = StyledItem;
export const AccordionTrigger = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
      <StyledChevron aria-hidden />
    </StyledTrigger>
  </StyledHeader>
));
export const AccordionContent = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    <StyledContentText>{children}</StyledContentText>
  </StyledContent>
));

const data = [
  {
    value: '#bucomaxilofacial-e-implantes-dentarios',
    title: 'Bucomaxilofacial e Implantes Dentários',
    content: [
      'Antes de qualquer coisa, o cirurgião bucomaxilofacial atua no tratamento de doenças da face e cavidade oral.',
      'Então vocês devem procurar para:',
      'Realização de enxertos ósseos e implantes dentários, exodontia dos sisos ou dentes inclusos, correções de deformidades dentoesqueléticas, disfunção da ATM (articulação temporomandibular), patologias orais e maxilofaicias, traumatismo facial e realização de enxertos ósseos e implantes dentários.',
      'Os dentes não são nosso único foco, somos responsáveis por toda complexidade que envolve a boca e a estrutura óssea da face.',
      'Aqui na Joy essa experiência com a cirurgia é única, pois proporcionamos conforto e individualização para cada paciente.'
    ]
  },
  {
    value: '#odontopediatra',
    title: 'Odontopediatra',
    content: [
      'A especialidade que envolve todos nossos pequenos, não importa a idade, pode ser desde a barriga da mãe até o início da adolescência.',
      'Com muito cuidado, utilizamos técnicas de manejo de comportamento, visando sempre um atendimento individualizado, de qualidade e ao mesmo tempo confortável, com acolhimento para a criança e sua família.',
      'Buscamos sempre técnicas atualizadas para o tratamento dos dentes de leite, afinal, eles são tão importantes quanto os permanentes! ',
      'Temos como mantra aqui na Joy que: Toda criança merece um odontopediatra pra chamar de seu!'
    ]
  },
  {
    value: '#endodontia',
    title: 'Endodontia',
    content: [
      'Essa especialidade é a que trata o canal do dente.',
      'O tratamento de canal é quando a polpa (nervo) do dente é danificada.',
      'Um dos maiores motivos dessa danificação são as cáries mais profundas, fraturas e traumas. Sendo necessário então, limpar o local e preenche-lo.',
      'Muitas pessoas pensam que para fazer canal o dente tem que doer, mas não é bem assim, em alguns casos, essa polpa entra em um processo de necrose, fazendo com que esse tratamento seja necessário mesmo que o paciente não tenha nenhum sintoma.',
      'É possível fazer o canal com menos desconforto possível e é isso que trazemos para cada paciente que necessita desse tratamento aqui na Joy.'
    ]
  },
  {
    value: '#estetica-dental',
    title: 'Estética Dental',
    content: [
      'A área da odontologia que recupera a forma e a função dos dentes afetados por cárie ou trauma, através da restauração e reconstrução.',
      'também é essa a especialidade que atua na modificação do contorno dos dentes para ajustes estéticos, com resinas compostas e lentes de contato, por exemplo.',
      'Devolvendo além da função, também a autoestima desse paciente.'
    ]
  },
  {
    value: '#invisalign',
    title: 'Invisalign',
    content: [
      'Seus dentes são desalinhados, tortinhos ou por algum motivo você não está satisfeito com eles?',
      'Sabemos que colocar aparelho muitas vezes é complicado, desconfortável e nada prático.',
      'Por isso, aqui na Joy, trabalhamos exclusivamente com o Invisalign que é um tratamento ortodôntico realizado com alinhadores invisíveis.',
      'Atende desde as queixas mais simples, as mais complicadas.'
    ]
  },
  {
    value: '#harmonizacao-facial',
    title: 'Harmonização Facial',
    content: [
      'A harmonização facial é um conjunto de procedimentos estéticos faciais que tem o intuito de trazer mais harmonia à face, considerando os ângulos e personalidade facial de cada um.',
      'Sendo com a toxina botulínica, preenchedores, bioestimuladores, fios de PDO, os procedimentos mais realizados para deixar o rosto mais harmônico e rejuvenescido.',
      'Podendo ser realizado em consultório e de maneira não cirúrgica, o paciente tem a oportunidade de se sentir ainda mais belo e mais confiante de si mesmo.',
      'Aqui na Joy trabalhamos sempre com os melhores materiais e com as técnicas mais atualizadas para trazer o melhor para esse paciente.'
    ]
  }
]

function renderItems(){
  return data.map( item => {
    return (
      <div key={item.title}>
        <AccordionItem value={item.value} id={item.value}>
          <AccordionTrigger id={item.value}>{item.title}</AccordionTrigger>
          <AccordionContent>
            {
              item.content.map( p => {
                return(
                  <>
                    {p}
                    <br />
                    <br />
                  </>
                )
              })
            }
          </AccordionContent>
        </AccordionItem>
      </div>
    )
  })
}

// Your app...
export const AccordionContainer = () => {
  const [valueAccordion, setValueAccordion] = useState()

  useEffect( () => {
    const listenToPopstate = () => {
      let valueOffset = 0
      switch (window.location.hash) {
        case '#bucomaxilofacial-e-implantes-dentarios':
          valueOffset = 150
        break;
        case '#odontopediatra':
          valueOffset = 200
        break;
        case '#endodontia':
          valueOffset = 300
        break;
        case '#estetica-dental':
          valueOffset = 300
        break;
        case '#invisalign':
          valueOffset = 350
        break;
        case '#harmonizacao-facial':
          valueOffset = 450
        break;
      
        default:
          break;
      }
      scroller.scrollTo('especialidades', {duration: 500, offset: valueOffset, smooth: true})
      setValueAccordion(window.location.hash)
    };

    window.addEventListener("popstate", listenToPopstate);
    return () => {
      window.removeEventListener("popstate", listenToPopstate); 
    }
  },[])

  return (
    <Accordion 
      type="single" 
      collapsible='true' 
      className='accordion-container' 
      value={valueAccordion} 
      onClick={(e) => setValueAccordion(e.target.id === valueAccordion ? '' : e.target.id)}
    >
      {renderItems()}
    </Accordion>
  )
}

export default AccordionContainer;
