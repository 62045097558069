import React from 'react';
import './app.scss';

//---Others---
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Inicio from '../pages/home/inicio/Inicio';
import Sobre from '../pages/home/sobre/Sobre';
import OndeEstamos from '../pages/home/ondeestamos/OndeEstamos';
import Especialidades from '../pages/home/especialidades/Especialidades';

function App() {
  return (
      <div className="app">
        <Header/>
        
        <Inicio/>
        <Especialidades/>
        <Sobre/>
        <OndeEstamos/>
       

        <Footer/>
      </div>    
  );
}

export default App;
