import React from 'react';
import './styles.scss'

import { MapContainer, TileLayer, Marker  } from 'react-leaflet'

const center = [-15.588462149140023, -56.10108076126361]
const zoom = 16
const markerPosition = [-15.588462149140023, -56.10108076126361]

function Map() {
  return ( 
    <>
      <div className="map_container">
        <MapContainer center={center} zoom={zoom} scrollWheelZoom={false} className='lefmap'>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={markerPosition} title='Joy Odonto'/>
        </MapContainer>
        <a className='visitar' target='_blank' href="https://www.google.com/maps/dir//Joy+Odonto+-+Av.+São+Sebastião,+3125+-+Sala+903+-+Andar+09+-+Quilombo,+Cuiabá+-+MT,+78045-000/@-15.6160841,-56.1145865,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x939db1062c26f267:0xd20efb07a5222f5e!2m2!1d-56.1011277!2d-15.588674!3e0" rel="noreferrer">
          Venha nos visitar!
        </a>
      </div>
    </>
   );
}

export default Map;